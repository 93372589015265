import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/one-time-charge';

export default {
    all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: payload})
    },
    get_by_flat(payload) {
        return axios.get(`${RESOURCE_NAME}/get-by-flat`, {
            params: {
                person_hash: payload.person_hash,
                date_start: payload.date_start
            }
        })
    },
    get_by_flat_legacy(payload) {
        return axios.get(`${RESOURCE_NAME}/get-by-flat`, {
            params: {
                person_hash: payload.person_hash,
                date_start: payload.date_start
            }
        })
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    create_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy`, payload)
    },
    create_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/create-by-flat`, payload)
    },
    create_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/create-by-flat`, payload)
    },
    create_service(payload) {
        return axios.post(`${RESOURCE_NAME}/create-service`, {name: payload})
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    update_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update`, payload)
    },
    update_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/update-by-flat`, payload)
    },
    update_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/update-by-flat`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, {"one_time_charge_id": payload})
    },
    remove_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove`, {"one_time_charge_id": payload})
    },
    remove_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/remove-by-flat`, {"remove_id": payload})
    },
    remove_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/legacy/remove-by-flat`, {"remove_id": payload})
    },
};